
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="充值金额" prop="buyAmount">
          <el-input-number v-model="formModel.buyAmount" 
          :precision="2" :step="1" :max="100"
          placeholder="请输入充值金额" size="medium"></el-input-number>元
        </el-form-item>
        <el-form-item label="当前电价" prop="useTypeV">
          <span v-html="formModel.useTypeV" style="margin-right:10px;"></span>元/度
        </el-form-item>
        <el-form-item label="充值度数" prop="buyElectricity" disabled>
          <span v-html="formModel.buyElectricity" placeholder="请输入充值度数" style="width:300px"></span>
        </el-form-item>
        <el-form-item label="用电类型" prop="useTypeN">
          <span v-html="formModel.useTypeN" style="margin-right:10px;"></span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import rechargeRecordApi from "@/api/base/rechargeRecord";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        buyAmount: [
          { required: true, message: "充值度数不能为空", trigger: "blur" },
          {
            type: "number",
            message: "请输入数字格式",
            trigger: "blur",
            transform(value) {
              return Number(value);
            }
          }
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        buyAmount : 0,
        buyElectricity:0,
        useTypeV: 0,
        useTypeN: ''
      }
    };
  },
  watch: {
    'formModel.buyAmount' : function(newVal,oldVal) {
      console.log(`newVal=${newVal},userTypeV=${this.formModel.useTypeV}`);

      if(this.formModel.useTypeV!=null && newVal!=null) {
        this.formModel.buyElectricity = parseInt(parseFloat(newVal) / parseFloat(this.formModel.useTypeV));
      }
      else{
        this.formModel.buyElectricity = 0;
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            self.formModel.clientId = self.businessKey;
            self.submitting = true;

            return rechargeRecordApi.saveRecord(self.formModel);
          })().then(function(response) {
            var jsonData = response.data;
            self.submitting = false;
            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;

    (function() {
      return rechargeRecordApi.queryRoomMessage(self.businessKey);
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          //self.formModel = jsonData.data;
          self.formModel.useTypeN = jsonData.data.useTypeN;
          self.formModel.useTypeV = jsonData.data.useTypeV;
          self.formModel.buyAmount = 0;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>