<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/electricClientInfo">线下充值</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="园区" prop="areaId" >
       <el-select
            size="mini"
            v-model="queryModel.areaId"
            filterable
            placeholder="请选择"
            @change="changeArea"
          >
            <el-option
              v-for="area in queryAreaResult"
              :key="area.id"
              :label="area.name"
              :value="area.id"
            ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="楼栋" prop="buildingId" >
      <el-select
            size="mini"
            v-model="queryModel.buildingId"
            filterable
            placeholder="请选择"
            @change="changeBuilding"
          >
            <el-option
              v-for="building in queryBuildingResult"
              :key="building.id"
              :label="building.name"
              :value="building.id"
            ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房间" prop="roomsId" >
      <el-select
            size="mini"
            v-model="queryModel.roomsId"
            filterable
            placeholder="请选择"
            @change="changeRoom"
          >
            <el-option
              v-for="rooms in queryRoomResult"
              :key="rooms.id"
              :label="rooms.name"
              :value="rooms.id"
            ></el-option>
        </el-select>
      </el-form-item>
      <pre></pre>
      <el-form-item label="户号" prop="customerNo">
        <el-input type="text" size="mini" v-model="queryModel.customerNo"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group"></el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
    >
      <el-table-column
        prop="customerNo"
        sort-by="customer_no"
        label="户号"
        sortable="custom"
        width="120"
      ></el-table-column>
      <el-table-column prop="area.name" label="园区" sortable="custom" width="120"></el-table-column>
      <el-table-column prop="building.name" label="楼栋" sortable="custom" width="120"></el-table-column>
      <el-table-column prop="room.name" label="房间" sortable="custom" width="120"></el-table-column>
      <el-table-column
        prop="meterName"
        sort-by="meter_name"
        label="电表名称"
        sortable="custom"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="room.useTypeN"
        sort-by="use_type"
        label="用电类型"
        sortable="custom"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="electricityRemaining"
        sort-by="electricity_remaining"
        label="剩余电量"
        sortable="custom"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="rechargeTimes"
        sort-by="recharge_times"
        label="充电次数"
        sortable="custom"
        width="180"
      ></el-table-column>      
      <el-table-column
        label="开关"
        width="80"
        fixed="right"
      >
        <template slot-scope="{row}">
          <el-switch
            v-model="row.active"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="powerSwitch(row)"
            >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="toRechange(row)">线下充值</el-button>
          <!-- <el-button size="mini" type="success" @click="powerSwitch(row,1)">打开电源</el-button>
          <el-button size="mini" type="danger" @click="pwoerSwitch(row,2)">关闭电源</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <rechange-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></rechange-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import RechangeDetail from "./rechange-detail";
import electricClientInfoApi from "@/api/base/electricClientInfo";
import roomInfoApi from "@/api/base/roomInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseRechangeList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        customerNo: "",
        roomId: "",
        meterId: "",
        electricityTotal: "",
        amountTotal: "",
        buyNum: "",
        electricityRemaining: "",
        amountRemaining: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        delFlag: "",
        areaId: "",
        buildingId: "",
        roomsId: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      queryAreaResult:[],
      queryBuildingResult:[],
      queryRoomResult:[]
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("customerNo", self.queryModel.customerNo);
      formData.append("roomId", self.queryModel.roomId);
      formData.append("meterId", self.queryModel.meterId);
      formData.append("electricityTotal", self.queryModel.electricityTotal);
      formData.append("amountTotal", self.queryModel.amountTotal);
      formData.append("buyNum", self.queryModel.buyNum);
      formData.append(
        "electricityRemaining",
        self.queryModel.electricityRemaining
      );
      formData.append("amountRemaining", self.queryModel.amountRemaining);
      formData.append("areaId", self.queryModel.areaId);
      formData.append("buildingId", self.queryModel.buildingId);
      formData.append("roomsId", self.queryModel.roomsId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      electricClientInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.changePage(this.pageIndex);
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";
      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    toRechange(record) {
      this.modalTitle = "充值";
      this.businessKey = record.id;
      this.showModal = true;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    selectRoomList(keywords,roomType){
        var roomData = new FormData();
        roomData.append("parentId",keywords);
        roomData.append("type",roomType);
        roomData.append("limit",100);

        return roomInfoApi.query(roomData).then(response=>{
          var jsonData = response.data;

          if(jsonData.result){
            console.log(jsonData.data);
            if(roomType == "1"){
              this.queryAreaResult = jsonData.data;
            }else if(roomType == "2"){
              this.queryModel.building = "";
              this.queryModel.room = "";
              this.queryBuildingResult = jsonData.data;
            }else{
              this.queryModel.room = "";
              this.queryRoomResult = jsonData.data;
            }
          }
          else{
            this.$message.error(jsonData.message + "");
          }
        });
    },
    querySelectArea() {
      this.queryModel.area = "";
      this.queryModel.building = "";
      this.queryModel.rooms = "";
      this.selectRoomList("","1");
    },
    changeArea(keywords) {
      this.queryModel.rooms = "";
      this.queryRoomResult = "";
      this.queryModel.building = "";
      this.queryBuildingResult = "";
      this.selectRoomList(keywords,"2");
    },
    changeBuilding(keywords) {
      this.queryModel.rooms = "";
      this.queryRoomResult = "";
      this.selectRoomList(keywords,"3");
    },
    changeRoom(keywords){
      this.$forceUpdate();
    },
    powerSwitch(record) {
      var cmd = record.active ? 1 : 0;
      electricClientInfoApi.powerSwitch(record.roomId, cmd).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.$message({
            message: "操作成功!",
            type: "success"
          });
        } else {
          this.$message({
            message: jsonData.message + "",
            type: "warning"
          });
        }
      });
    }
  },
  mounted: function() {
    this.querySelectArea();
    this.changePage(1);
  },
  components: {
    "rechange-detail": RechangeDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>